body {
  text-transform: uppercase;
}
.footer-text {
  font-size: 14px;
}
.home-text {
  font-size: 14px;
}
.home-image {
  display: block;
  margin: 0px 46% 0px 5%;
}

@media only screen and (max-width: 400px) {
  .home-image .profile-photo {
    width: 150px;
    margin-left: 30px;
  }
}
@media only screen and (max-width: 275px) {
  .home-image .profile-photo {
    width: 130px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 600px) {
  #mini-menu {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  #big-nav {
    display: none;
  }
  #black-white-h1 {
    font-size: 1em;
  }
  #color-h1 {
    font-size: 1em;
  }
}
